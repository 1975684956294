import React from 'react'

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 23 17" fill="none" {...props}>
    <path
      d="M20.52 16.6H2.36C1.06 16.6 0 15.54 0 14.24V2.36C0 1.06 1.06 0 2.36 0h18.16c1.3 0 2.36 1.06 2.36 2.36v11.88c0 1.3-1.06 2.36-2.36 2.36zM2.36 2c-.2 0-.36.16-.36.36v11.88c0 .2.16.36.36.36h18.16c.2 0 .36-.16.36-.36V2.36c0-.2-.16-.36-.36-.36H2.36z"
      fill="#F2EEE8"
    />
    <path
      d="M20.52 16.1H2.36c-.77 0-1.46-.48-1.73-1.2a.514.514 0 01.13-.55l7.09-6.32c.21-.18.52-.17.71.04.18.21.17.52-.04.71l-6.79 6.05c.16.17.39.28.63.28h18.16c.24 0 .47-.1.63-.28L14.29 8.7c-.2-.18-.22-.5-.04-.71.18-.21.5-.22.71-.04l7.17 6.39c.15.14.21.36.13.55-.28.73-.97 1.21-1.74 1.21z"
      fill="#F2EEE8"
    />
    <path
      d="M11.44 11.61a.46.46 0 01-.33-.13L.76 2.25a.518.518 0 01-.14-.55C.9.98 1.59.5 2.36.5h18.16c.77 0 1.46.48 1.73 1.2.07.19.02.41-.13.55l-10.35 9.23c-.09.08-.21.13-.33.13zM1.73 1.78l9.71 8.66 9.71-8.66a.841.841 0 00-.63-.28H2.36c-.24 0-.47.1-.63.28z"
      fill="#F2EEE8"
    />
  </svg>
)

export default SvgComponent
