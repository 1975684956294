import React from 'react'

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8.889c-3.017 0-3.396.013-4.58.067-1.184.054-1.991.241-2.697.516a5.44 5.44 0 00-1.969 1.281 5.454 5.454 0 00-1.282 1.968c-.276.707-.463 1.515-.516 2.697-.053 1.185-.067 1.564-.067 4.582 0 3.017.013 3.395.067 4.58.054 1.183.241 1.99.516 2.697.284.73.664 1.35 1.282 1.968a5.441 5.441 0 001.967 1.283c.707.274 1.515.462 2.697.516 1.186.054 1.564.067 4.581.067 3.018 0 3.396-.013 4.58-.067 1.184-.054 1.992-.242 2.699-.516a5.436 5.436 0 001.967-1.283 5.453 5.453 0 001.282-1.968c.273-.707.461-1.514.517-2.697.053-1.185.067-1.563.067-4.58 0-3.018-.014-3.396-.067-4.582-.056-1.183-.244-1.99-.517-2.696a5.452 5.452 0 00-1.282-1.969 5.43 5.43 0 00-1.968-1.281c-.708-.275-1.516-.462-2.699-.516-1.185-.054-1.562-.067-4.58-.067H20zm-.37 2.003h.371c2.967 0 3.318.01 4.49.064 1.083.05 1.671.23 2.063.382.519.202.888.442 1.277.831.389.39.63.76.831 1.278.152.391.334.98.383 2.063.053 1.17.065 1.523.065 4.488 0 2.965-.012 3.317-.065 4.488-.05 1.084-.23 1.672-.383 2.063a3.434 3.434 0 01-.831 1.276c-.39.39-.758.63-1.277.831-.391.153-.98.334-2.063.383-1.171.053-1.523.065-4.49.065-2.967 0-3.318-.012-4.49-.065-1.083-.05-1.671-.23-2.063-.383a3.441 3.441 0 01-1.278-.831 3.443 3.443 0 01-.831-1.277c-.152-.391-.333-.98-.383-2.063-.053-1.17-.064-1.523-.064-4.49 0-2.967.011-3.317.064-4.488.05-1.084.23-1.672.383-2.063.201-.519.442-.89.831-1.278.39-.389.76-.63 1.278-.832.392-.152.98-.333 2.063-.383 1.025-.046 1.423-.06 3.493-.062v.003h.626zm4.968 3.177a1.334 1.334 0 112.666 0 1.334 1.334 0 01-2.666 0zM20 14.293a5.706 5.706 0 100 11.413 5.706 5.706 0 000-11.413zM23.704 20a3.704 3.704 0 10-7.407 0 3.704 3.704 0 007.407 0z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
